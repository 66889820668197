import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { Container, Input, TextArea } from "@util/standard";
import { AppLayout } from "@shared/salesTool";
import { ParentWrapper } from "@shared/salesTool/components.styles";
import { Button, ConfirmationModal, Dropdown, Loading } from "@global";
import { useSelectCustomerOptions, useCreateOrderToFirestore } from "@util/firebaseHooks";
import { useStore } from "@state/store";
import { isBrowser } from "@util/helper";
import { ConfirmationModalProps } from "../../global/confirmationModal";
import { appPaths } from "@util/constants";

const defaultState = {
  season: "",
  hasSent: false,
  notes: "",
};

export const ConfirmDeleteOrderModal = (props: ConfirmationModalProps) => (
  <ConfirmationModal
    {...props}
    message="Are you sure you would like to delete this order? This will delete the order from the system and cannot be undone."
  />
);

export default function CreatOrder() {
  if (!isBrowser()) return null;
  const { selectedCustomer, setCreateCustomerVisible, order } = useStore();
  const [formData, setFormData] = useState(defaultState);

  const [selectedOption, setSelectedOption, customerOptions] = useSelectCustomerOptions();
  const [orderCreated, loading, handleSelectProduct] = useCreateOrderToFirestore(formData);

  const handleRightButtonClick = () => navigate(appPaths.orders);

  useEffect(() => {
    if (orderCreated) {
      navigate("/calendars");
    }
  }, [orderCreated]);

  return (
    <AppLayout currentPath="createOrder" onRightButtonClick={handleRightButtonClick}>
      <ParentWrapper>
        <Container width="70%" margin="auto" flexDirection="column">
          <Container>
            <h2>{order && orderCreated ? `Order id: ${order.id}` : "Create Order"}</h2>
          </Container>
          <Container flexDirection="column">
            <TextArea
              placeholder="Order notes"
              onChange={e => setFormData({ ...formData, notes: e.target.value })}
              value={formData.notes}
            />
            <Container margin="30px 0" flexDirection="column">
              <p>Select an existing customer or create customer</p>
              <Container width="100%">
                {customerOptions ? (
                  <Dropdown
                    name="customers"
                    defaultTitle="Customer"
                    options={customerOptions}
                    optionState={[selectedOption, setSelectedOption]}
                    disabled={orderCreated}
                  />
                ) : (
                  <Loading width="20px" height="20px" baseColor="navy" />
                )}
                {!orderCreated && (
                  <Button
                    margin="0 0 0 20px"
                    linkText="Create customer"
                    width="220px"
                    onClick={() => setCreateCustomerVisible(true)}
                  />
                )}
              </Container>
            </Container>
            <p>Season</p>
            <Input
              name="season"
              placeholder="Year"
              onChange={e => setFormData({ ...formData, season: e.target.value })}
              value={formData.season}
            />
            {selectedCustomer && (
              <Button
                loading={loading}
                margin="40px 0 0 0"
                linkText="Select product"
                onClick={handleSelectProduct}
              />
            )}
          </Container>
        </Container>
      </ParentWrapper>
    </AppLayout>
  );
}
